





































import { Vue, Component, PropSync } from 'vue-property-decorator';
import ContractSavingsActionsViewModel
  from '@/vue-app/view-models/components/contract-savings/contract-savings-actions-view-model';

@Component({
  name: 'ContractSavingsActions',
})
export default class ContractSavingsActions extends Vue {
  @PropSync('firstActionText', {
    type: String,
    required: false,
    default: 'Regresar',
  })
  readonly first_action_text!: string;

  @PropSync('serviceName', { type: String, default: 'allianz' })
  readonly service!: string;

  @PropSync('isLoading', { type: Boolean })
  readonly is_loading!: boolean;

  @PropSync('btnDisabled', { type: Boolean })
  readonly btn_disabled!: boolean;

  view_model = Vue.observable(
    new ContractSavingsActionsViewModel(this),
  );
}

